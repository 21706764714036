import * as React from "react";
import { graphql } from "gatsby";
import { ICorpGroupBookingProps } from "../pageHelpers/CorpGroupBooking/CorpGroupBookingProps";
import {
  MessageContainer,
  StyledContainer,
} from "../pageHelpers/CorpGroupBooking/CorpGroupBookingHelpers";
import Layout from "../components/global/Layout/Layout";
import DeepLinking from "../components/common/DeepLinking/DeepLinking";

const CorpGroupBooking: React.FC<ICorpGroupBookingProps> = (props) => {
  const _site = props.data.allRlhsite.edges[0].node;

  return (
    <Layout {...{ site: _site }}>
      <StyledContainer fluid="sm" className="pt-4 pb-4 pt-lg-5 pb-lg-5">
        <MessageContainer>
          <DeepLinking location={props.location} />
        </MessageContainer>
      </StyledContainer>
    </Layout>
  );
};

export const pageQuery = graphql`
  query CorpGroupBookingQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allRlhsite(filter: { machine_name: { eq: "sonesta" } }) {
      edges {
        node {
          machine_name
          relationships {
            paragraphs: field_sections {
              type: __typename
              ...ParagraphPageBanner
              ...ParagraphPageIntro
              ...ParagraphFeaturedDestinations
              ...ParagraphMediaCta
            }
          }
        }
      }
    }
  }
`;
export default CorpGroupBooking;
